import React from "react"

import PageWrapper from "../components/PageWrapper"

import TermsAndConditions from "../features/LegalStuff/TermsAndConditions"

const TermsAndConditionStandalonePage = ({ location }) => {
  return (
    <PageWrapper location={location} isStandalonePage>
      <TermsAndConditions />
    </PageWrapper>
  )
}

export default TermsAndConditionStandalonePage
